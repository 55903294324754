import { getQueryString } from "@/util/helper";
import axios from "@/util/axios";

const loadFilterValue = () => {
    const filterValue = JSON.parse(localStorage.getItem("filterValue"));
    return filterValue
        ? filterValue
        : {
            filter: false,
            industry: ""
        };
};

const state = {
    filterValue: loadFilterValue(),
    brands: [],
    search: "",
    searchLoading: false,
    startSearch: false,
    closeSearchBar: false,
};

const getters = {
    getAllBrands: (state) => state.brands,
    getFilterValue: (state) => {
        return state.filterValue;
    },
};

const mutations = {
    setFilterValue: (state, payload) => {
        state.filterValue = payload;
        localStorage.setItem("filterValue", JSON.stringify(payload));
    },
    doNothing: (state) => state,
    setAllBrands: (state, payload) => (state.brands = payload),
    addToBrand: (state, payload) => state.brands.unshift(payload),
    updateToBrand: (state, updatedBrand) => {
        state.brands.forEach((brand) => {
            if (brand.id == updatedBrand.id) {
                // Update Data
                brand.name = updatedBrand.name;
                brand.link = updatedBrand.link;
                brand.logo = updatedBrand.logo;
                brand.desc = updatedBrand.desc;
                brand.industry = updatedBrand.industry;
                brand.brand_contacts = updatedBrand.contacts;
            }
        });
    },
    removeBrand: (state, id) => {
        state.brands = state.brands.filter((u) => u.id !== id);
    },
    setSearch(state, value) {
        state.search = value;
    },
    setSearchLoading(state, value) {
        state.searchLoading = value;
    },
    setStartSearch(state, value) {
        state.startSearch = value;
    },
    setCloseSearchBar(state, value) {
        state.closeSearchBar = value;
    },
};

const actions = {
    updateFilterValue: ({ commit }, filterValue) => {
        commit("setFilterValue", filterValue);
    },

    updateSearch({ commit }, value) {
        commit("setSearch", value);
    },
    updateSearchLoading({ commit }, value) {
        commit("setSearchLoading", value);
    },
    updateStartSearch({ commit }, value) {
        commit("setStartSearch", value);
    },
    updateCloseSearchBar({ commit }, value) {
        commit("setCloseSearchBar", value);
    },

    /**
     * @description Get All User
     * @param {Number} data - brands Object
     * @returns Promise
     */
    async fetchAllBrands({ commit }, options) {
        return new Promise((resolve, reject) => {
            let params = {};
            if (options.all) {
                params = options;
            } else {
                const { sortBy, sortDesc, page, itemsPerPage } = options;
                let sort_by = sortBy[0];
                let sort_order = sortDesc[0] ? "DESC" : "ASC";

                params = {
                    page: page,
                    per_page: itemsPerPage,
                    sort_by: sort_by,
                    sort_order: sort_order,
                };
            }
            let url = getQueryString(params).substring(1);

            axios
                .get(`/v1/brands?${url}`)
                .then(async (res) => {
                    if (res.data.success) {
                        await commit("setAllBrands", res.data?.data?.data);
                        resolve(res);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    /**
     * @description Update User Profile
     * @param {Object} data - Update Profile Data Object
     * @returns Promise
     */
    updateBrand({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .put(`/v1/brands/${data._id}`, data)
                .then((res) => {
                    if (res.data.success) {
                        commit("updateToBrand", data);
                        resolve(res);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * @description Search User
     * @param {Number} data - User Object
     * @returns Promise
     */

    searchBrand({ commit }, options) {
        return new Promise((resolve, reject) => {
            let params = {};
            if (options.all) {
                let name = options.filter;
                params = { name, all: true };
            } else {
                const { page, itemsPerPage } = options;
                params = {
                    page: page,
                    per_page: itemsPerPage,
                };
                let name = options.filter;
                params = { name, ...params };
            }

            let url = getQueryString(params).substring(1);

            axios
                .get(`/v1/brands/search?${url}`)
                .then((res) => {
                    if (res.data.success) {
                        commit("setAllBrands", res.data?.data?.data);
                        resolve(res);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    /**
     * @description Remove User
     * @param {Object} data - Remove User
     * @returns Promise
     */
    removeBrandById({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/v1/brands/${id}`)
                .then((res) => {
                    if (res.data.success) {
                        commit("removeBrand", id);
                        resolve(res);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    /**
     * @description Get Brand Info by ID
     * @param {Number} id - User ID
     * @returns Promise
     */
    fetchBrandByID({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/v1/brands/${id}`)
                .then((res) => {
                    if (res.data.success) {
                        commit("doNothing");
                        resolve(res);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    /**
     * @description Create New User by SUPER ADMIN
     * @param {Object} data - User data Object
     * @returns Promise
     */
    createBrand({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/v1/brands`, data)
                .then((res) => {
                    if (res.data.success) {
                        commit("addToBrand", res.data.data);
                        resolve(res);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    /**
   * @description Filter Brands
   * @param {Number} id - industry
   * @returns Promise
   */
    async filterBrands({ commit }, options) {
        return new Promise((resolve, reject) => {
            let params = {};
            if (options.all) {
                params = {
                    ...options, country: state.filterValue.country,
                    industry: state.filterValue.industry,

                };
            } else {
                const { sortBy, sortDesc, page, itemsPerPage } = options;
                let sort_by = sortBy[0];
                let sort_order = sortDesc[0] ? "DESC" : "ASC";

                params = {
                    page: page,
                    per_page: itemsPerPage,
                    sort_by: sort_by,
                    sort_order: sort_order,
                    industry: state.filterValue.industry,
                };
            }
            let url = getQueryString(params).substring(1);

            axios
                .get(`/v1/brands/filter?${url}`)
                .then(async (res) => {
                    if (res.data.success) {
                        await commit("setAllBrands", res.data?.data?.data);
                        resolve(res);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
