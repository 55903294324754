import Vue from "vue";
import Vuex from "vuex";
import authStore from "./modules/auth.store";
import userStore from "./modules/user.store";
import permissionStore from "./modules/permission.store";
import roleStore from "./modules/role.store";
import officeStore from "./modules/office.store";
import landlordStore from "./modules/landlord.store";
import companyStore from "./modules/company.store";
import propertyStore from "./modules/property.store";
import brandRequirementStore from "./modules/brandRequirement.store";
import smartSearchStore from "./modules/smartSearch.store";
import summaryStore from "./modules/summary.store";
import agpropAiStore from "./modules/agpropAi.store";
import tagStore from "./modules/tag.store";
import otpStore from "./modules/otp.store";
import brandStore from "./modules/brand.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: true,
    leftSidePanelStatus: false,
    snackbar: {
      edialogVisible: false,
      eMessage: "",
      etitle: "",
      isSnackBarVisible: false,
      snackBarMessage: "",
    },
  },
  getters: {
    getSnackbarMessage: (state) => state.snackbar.snackBarMessage,
    getEdialogMessage: (state) => state.snackbar.eMessage,
    getEdialogTitle: (state) => state.snackbar.etitle,
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: (state) => (state.drawer = !state.drawer),
    toggleEdialog: (state) => (state.snackbar.edialogVisible = !state.snackbar.edialogVisible),
    setEdialogMessage: (state, payload) => (state.snackbar.eMessage = payload),
    setEdialogTitle: (state, payload) => (state.snackbar.etitle = payload),
    setEdialogVisible: (state, payload) => (state.snackbar.edialogVisible = payload),
    setSnackbarVisible: (state, payload) =>
      (state.snackbar.isSnackBarVisible = payload),
    toggleSnackbar: (state) =>
      (state.snackbar.isSnackBarVisible = !state.snackbar.isSnackBarVisible),
    setSnackbarMessage: (state, payload) => (state.snackbar.snackBarMessage = payload),
    toggleLeftSidePanelStatus: (state, value = '') =>
    (state.leftSidePanelStatus = value !== '' ? value : !state.leftSidePanelStatus),
  },
  actions: {},
  modules: {
    authStore,
    userStore,
    permissionStore,
    roleStore,
    officeStore,
    landlordStore,
    companyStore,
    propertyStore,
    brandRequirementStore,
    smartSearchStore,
    summaryStore,
    otpStore,
    agpropAiStore,
    tagStore,
    brandStore
  },
});
